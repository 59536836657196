import axios from "axios"

export const services = ["Garden Maintainance", "Garden Development", "Kitchen Gardening"]?.map(i => ({
	label: i,
	key: i.toLowerCase().replace(/ /g, "_")
}))

const links = [
	{ id: "pwu", label: "Partner With Us", path: "/partner-with-us" },
	{ id: "mb", label: "My Business", path: "/business", auth: true, business: true },
	{ id: "gl", label: "Guide Links", path: "/guide-links", auth: true },
	{ id: "gallery", label: "Gallery", path: "/gallery" },
	{ id: "blogs", label: "Blogs", path: "/blogs" }
]

export const getLinks = (masterState, pathname) => {
	return links
		.filter(i => i.business || !pathname || !pathname.includes(i.path))
		.filter(i => !i.auth || masterState?.token)
		.filter(i => !i.services || masterState?.services)
		.filter(i => !i.business || (i.business === -1 ? !masterState?.businesses : masterState?.businesses))
}

export const imageToURL = (event, callback) => {
	const file = event.target.files[0]
	if (!file) return
	const reader = new FileReader()

	reader.onload = function () {
		let url = reader.result
		callback({ file, url, name: file.name })
	}

	reader.readAsDataURL(file)
}

export const selectImage = () =>
	new Promise((resolve, reject) => {
		const input = document.createElement("input")
		input.type = "file"
		input.accept = "image/*"
		input.addEventListener("change", e => {
			// const size = e.target.files?.[0]?.size / 1024
			// if (size > 400) return reject("The image size should be less than 400kb")
			imageToURL(e, resolve)
		})
		input.click()
	})

export const generateOTP = async phone_number => {
	try {
		await axios.get("/otp/generate", { params: { phone_number } })
		return true
	} catch (error) {
		return null
	}
}

export const verifyOTP = async (phone_number, OTP) => {
	try {
		const response = await axios.get("/otp/verify", {
			params: {
				phone_number,
				OTP
			}
		})
		return response.data.verified
	} catch (error) {
		return null
	}
}
