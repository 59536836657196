import React from "react"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import { useSearchParams } from "react-router-dom"
import ScrollToTop from "../../utils/ScrollToTop"

const Layout = ({ children, ...props }) => {
	const [searchParams] = useSearchParams()
	const isMobileView = Boolean(searchParams.get("mobile_view"))

	return (
		<div className="page-wrapper static-page" {...props}>
			{!isMobileView && <Navbar />}
			<ScrollToTop>
				<div className="container content">{children}</div>
			</ScrollToTop>
			{!isMobileView && <Footer />}
		</div>
	)
}

export default Layout
