import React, { useState } from "react"
import { ReactComponent as Add } from "../assets/add.svg"
import { ReactComponent as Check } from "../assets/checksign.svg"
import { ReactComponent as Cross } from "../assets/cross.svg"
import { MdOutlineReplay } from "react-icons/md"
import { BsCheckAll } from "react-icons/bs"
import { selectImage } from "../utils/methods"
import Prompt from "./Prompt"
import compressImage from "../utils/compressImage"

const ImageUpload = ({
	images,
	setImages,
	heading,
	instruction,
	close,
	highlightImages,
	wrapperClasses = "",
	imagesPerRow,
	maxCount,
	disabled,
	imageSize,
	id,
	__ref
}) => {
	const [removeSelection, setRemoveSelection] = useState([])
	const [promptState, setPromptState] = useState()

	async function uploadImage() {
		try {
			if (maxCount && images?.filter(i => !i.discarded)?.length === maxCount)
				throw Error(`Maximum ${maxCount} images can be selected`)
			const data = await selectImage()
			if (imageSize) data.file = await compressImage(data.file, imageSize)
			setImages(i => [...(i || []), data])
		} catch (error) {
			setPromptState(error?.message || error)
		}
	}

	const onImageClick = (id, discarded) => {
		if (discarded)
			setImages(prev => prev.map((_i, _idx) => (id === _i.name + _idx ? { ..._i, discarded: null } : _i)))
		else setRemoveSelection(prev => (prev?.includes(id) ? prev?.filter(_i => _i !== id) : prev?.concat([id])))
	}

	return (
		<div className={"section upload-image " + wrapperClasses} id={id} ref={__ref}>
			<div>
				<div>
					<h2 className="font-l">{heading}</h2>
					<div className="font-s">
						<span>{instruction}</span>
					</div>
				</div>
				<div className="flex">
					{images?.length > 0 && (
						<button
							type="button"
							className={`theme-btn small ${!removeSelection.length ? "grey" : "danger"} flex `}
							onClick={() => {
								setImages(prev =>
									prev
										.map((i, idx) =>
											removeSelection.includes(i.name + idx) ? (i.uploaded ? { ...i, discarded: true } : false) : i
										)
										.filter(i => i)
								)
								setRemoveSelection([])
							}}
							disabled={!removeSelection.length || disabled}
						>
							<Cross width={"15px"} height={"15px"} /> Remove ({removeSelection.length})
						</button>
					)}
					<button type="button" className="theme-btn small primary flex" onClick={uploadImage} disabled={disabled}>
						<Add stroke="white" width={"20px"} height={"20px"} /> Add Image
					</button>
					{close && (
						<button type="button" className="flex" onClick={close} disabled={disabled}>
							<Cross width={"24px"} height={"24px"} />
						</button>
					)}
				</div>
			</div>
			<div className="theme-gallery" style={imagesPerRow ? { "--image-per-row": imagesPerRow } : null}>
				{images?.[0] ? (
					images?.map((i, idx) => (
						<div onClick={() => (!disabled ? onImageClick(i.name + idx, i.discarded) : null)}>
							<img src={i.url} alt={i.name} />
							{removeSelection?.includes(i.name + idx) && (
								<div className="flex selection">
									<Check />
								</div>
							)}
							{i.discarded && (
								<div className="flex selection">
									<MdOutlineReplay className="theme-font heading" />
								</div>
							)}
							{highlightImages?.includes(i.name) && (
								<div className="flex highlight">
									{highlightImages?.length === 1 ? (
										<BsCheckAll className="theme-font font-l" />
									) : (
										<span className="theme-font">1</span>
									)}
								</div>
							)}
						</div>
					))
				) : (
					<em className="font-xs" style={{ textAlign: "center", width: "100%" }}>
						No Image Selected
					</em>
				)}
			</div>
			{promptState && (
				<Prompt
					heading="Image Selection Error"
					message={promptState}
					Actions={() => (
						<button className="theme-btn danger outlined" onClick={() => setPromptState()}>
							Okay
						</button>
					)}
				/>
			)}
		</div>
	)
}

export const ImageUploadModal = props => (
	<div className="overlay">
		<div className="container flex image-modal">
			<ImageUpload {...props} />
		</div>
	</div>
)

export default ImageUpload
