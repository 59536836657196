import React, { useEffect, useState, useContext, useRef } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import { GoCheckCircle } from "react-icons/go"
import { RxCross2 } from "react-icons/rx"
import { GrPowerReset } from "react-icons/gr"
import "./styles.css"

import { MasterContext } from "../../context/MasterContext"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import NumberInput from "../../components/MobileInput"
import ImageUpload from "../../components/ImageUpload"
import { selectImage, services } from "../../utils/methods"
import { ReactComponent as Add } from "../../assets/add.svg"
import { ReactComponent as Check } from "../../assets/checksign.svg"
import { ReactComponent as Down } from "../../assets/down.svg"
import { ReactComponent as Delete } from "../../assets/delete.svg"
import { ReactComponent as CheckBox } from "../../assets/checkbox.svg"
import { ReactComponent as CheckBoxEmpty } from "../../assets/checkboxempty.svg"
import { ReactComponent as Radio } from "../../assets/radio.svg"
import Select from "../../components/Select"
import Prompt from "../../components/Prompt"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import Places from "../../components/Map"
import compressImage, { imageSizes } from "../../utils/compressImage"

const businessNatures = [
	{ value: "plant_nursery", label: "Plant Nursery" },
	{ value: "fertilizer_shop", label: "Fertilizer Shop" },
	{ value: "tools_and_accessories", label: "Tools and Accessories" },
	{ value: "service", label: "Service" }
]

const deliveryTimeframe = [
	{
		value: "24h",
		label: "In 24 hours"
	},
	{
		value: "2_3d",
		label: "Within 2 to 3 days"
	}
]

const productTypes = [
	{ label: "Plants", value: "plants" },
	{ label: "Pots", value: "pots" },
	{ label: "Fertilizer", value: "fertilizer" },
	{ label: "Seeds", value: "seeds" },
	{ label: "Grow Bags", value: "grow_bags" },
	{ label: "Jute Bags", value: "jute_bags" },
	{ label: "Grass", value: "grass" },
	{ label: "Tools and accessories", value: "tools_and_accessories" }
]

const toAmount = i => i?.toLocaleString("en-IN", { maximumFractionDigits: 2 }) || 0
const orderPriceSlabs = [
	{ from: 1, upto: 1000 },
	{ from: 1001, upto: 10000 },
	{ from: 10001, upto: 100000 },
	{ from: 100001, upto: 500000 },
	{ from: 500001, upto: 1000000 },
	{ from: 1500001, upto: 2000000 },
	{ from: 2000001, upto: 2500000 }
]

const TOC = [
	{ id: "Business-Details", heading: "Business Details", details: "Business name, nature and behaviour" },
	{ id: "Delivery-Charges", heading: "Delivery Charges" },
	{ id: "Location-Details", heading: "Location Details", details: "Address, location and geolocation" },
	{ id: "Legal-Details", heading: "Legal & Bank Details", details: "Business legal and bank details" },
	{ id: "Contact-Number", heading: "Contact Number", details: "Primary and secondary" },
	{ id: "Discount-Table", heading: "Discount Table", details: "Discount on billing amount" },
	{ id: "Upload-Images", heading: "Upload Images", details: "Upload business images" },
	{ id: "Business-Covers", heading: "Business Covers", details: "Upload business cover images" }
]

const BusinessForm = () => {
	const [queryParams] = useSearchParams()
	const [data, setData] = useState({})
	const isNatureService = data?.business_nature === businessNatures.at(-1).value
	const toc = isNatureService ? [TOC?.[0]].concat(TOC.slice(2, 8)) : TOC

	const { business_id } = useParams()
	const Navigate = useNavigate()
	const sectionRefs = useRef(toc?.map(() => React.createRef()))
	const { masterState, setMasterState } = useContext(MasterContext)

	const [loading, setLoading] = useState()
	const [selectedImages, setSelectedImages] = useState([])
	const [coverImages, setCoverImages] = useState()
	const [promptState, setPromptState] = useState()
	const [verifiedNumbers, setVerifiedNumbers] = useState({})
	const [activeSectionIndex, setActiveSectionIndex] = useState(0)
	const [geolocation, setGeolocation] = useState(null)
	const [deliveryChargeCalculator, setDeliveryChargeCalculator] = useState({})

	const businessDisabled = business_id && (!data?.active_status?.by_vendor || data?.active_status?.by_admin <= 0)

	useEffect(() => {
		const handleScroll = e => {
			const scrollPosition = window.scrollY + 35
			const newActiveSection = sectionRefs.current.findIndex(ref => ref?.current?.offsetTop > scrollPosition)
			const curr_idx = newActiveSection === 0 ? 0 : newActiveSection !== -1 ? newActiveSection - 1 : toc.length - 1
			setActiveSectionIndex(curr_idx)
		}

		document.addEventListener("scroll", handleScroll)
		return () => document.removeEventListener("scroll", handleScroll)
	}, [])

	useEffect(() => {
		if (!business_id) {
			const businessType = queryParams.get("nature")
			console.log({ businessType })
			if (businessType === "service") setData({ business_nature: businessType })
			return
		}
		;(async () => {
			try {
				setLoading("spinner")

				const response = await axios.get(`/business/user-business/${business_id}`, {
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
				})

				if (response.data) {
					const { geolocation: _coords, ...business } = response.data?.business

					setVerifiedNumbers(
						Object.values(response.data.business?.contact_number)?.reduce(
							(obj, num) => ({ ...obj, [num]: true }),
							{}
						)
					)

					if (_coords) setGeolocation(_coords)
					setData(business)
					setSelectedImages(
						response?.data?.images?.map(i => ({
							...i,
							path: i.path,
							url: `${axios.defaults.baseURL}/${i.path}`,
							uploaded: true
						}))
					)

					const cover_images = response.data.cover_images

					if (cover_images?.primary) {
						cover_images.primary = {
							...cover_images.primary,
							url: `${axios.defaults.baseURL}/${cover_images.primary.path}`,
							uploaded: true
						}
					}
					if (cover_images?.center?.top) {
						cover_images.center.top = {
							...cover_images.center.top,
							url: `${axios.defaults.baseURL}/${cover_images.center.top.path}`,
							uploaded: true
						}
					}
					if (cover_images?.center?.bottom) {
						cover_images.center.bottom = {
							...cover_images.center.bottom,
							url: `${axios.defaults.baseURL}/${cover_images.center.bottom.path}`,
							uploaded: true
						}
					}
					if (cover_images?.portrait) {
						cover_images.portrait = {
							...cover_images.portrait,
							url: `${axios.defaults.baseURL}/${cover_images.portrait.path}`,
							uploaded: true
						}
					}

					setCoverImages(cover_images)
				}
			} catch (error) {}
			setLoading()
		})()
	}, [business_id])

	const scrollToSection = i => document.getElementById(i.id).scrollIntoView({ block: "start", behavior: "smooth" })
	const onChange = e => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))

	const onChangeNested = e => {
		const key = e.target.name.slice(0, e.target.name.indexOf("["))
		setData(prev => ({
			...prev,
			[key]: {
				...(prev?.[key] || {}),
				[e.target.name.match(/\[\w+\]/)[0].slice(1, -1)]: e.target.value
			}
		}))
	}

	const onChangeIndexed = e => {
		const key = e.target.name.slice(0, e.target.name.indexOf("["))
		const [index, nestedKey] = e.target.name.match(/\[\w+\]/g)
		setData(prev => ({
			...prev,
			[key]: (prev?.[key] || [{}]).map((i, idx) =>
				idx === +index.slice(1, -1) ? { ...i, [nestedKey.slice(1, -1)]: e.target.value } : i
			)
		}))
	}

	const handleDeliveryCharge = (upto, percent, newIndex) =>
		setData(prev => {
			const { slabs } = prev.delivery_charges || {}
			const _slabs = slabs?.filter(i => i.upto !== upto) || []
			const lastIndex = orderPriceSlabs?.findIndex(i => i.upto === slabs?.at(-1)?.upto)
			let currPercent = 0

			if (slabs?.length && newIndex !== lastIndex) {
				for (let index = 0; index < newIndex; index++) {
					if (slabs?.find(i => i.upto === orderPriceSlabs?.[index]?.upto && +i.percent)) {
						currPercent = slabs[index]?.percent
						continue
					}

					if (currPercent)
						_slabs.push({
							upto: orderPriceSlabs?.[index]?.upto,
							percent: currPercent
						})
				}
				for (let index = newIndex + 1; index < lastIndex; index++) {
					if (_slabs?.find(i => i.upto === orderPriceSlabs?.[index]?.upto && +i.percent)) break

					_slabs.push({
						upto: orderPriceSlabs?.[index]?.upto,
						percent: slabs?.at(-1)?.percent
					})
				}
			}

			if (!percent && currPercent && newIndex !== lastIndex) percent = currPercent
			if (percent)
				_slabs.push({
					upto,
					percent
				})

			return {
				...prev,
				delivery_charges: {
					...(prev.delivery_charges || {}),
					slabs: _slabs?.sort((a, b) => a.upto - b.upto)
				}
			}
		})

	const onSubmit = async e => {
		try {
			e.preventDefault()
			const unverifiedNumbers = Object.values(data?.contact_number)?.filter(i => !verifiedNumbers[i])
			if (unverifiedNumbers?.length) {
				setPromptState({
					heading: "Unverified Mobile Numbers",
					message: `Please verify the provided mobile number(s).`,
					info: unverifiedNumbers?.join(", "),
					Actions: () => (
						<button className="theme-btn outlined danger" onClick={() => setPromptState(false)}>
							Okay
						</button>
					)
				})
				return
			}

			if (!geolocation?.googlemap_placeid) {
				setPromptState({
					heading: "Business Geolocation",
					message:
						"Accurately locate your business on Google Maps to calculate delivery distance and cost for orders.",
					Actions: () => (
						<button className="theme-btn outlined danger" onClick={() => setPromptState(false)}>
							Okay
						</button>
					)
				})
				return
			}

			if (!data?.city) {
				setPromptState({
					heading: "No City Selected",
					message: `Please select your business city to continue.`,
					Actions: () => (
						<button className="theme-btn outlined danger" onClick={() => setPromptState(false)}>
							Okay
						</button>
					)
				})
				return
			}

			setLoading(true)
			const formData = new FormData(e.target)

			formData.append("city", data?.city)

			if (formData.get("deliver_all_over_india") === "on") formData.set("deliver_all_over_india", true)

			if (selectedImages?.[0])
				for (const image of selectedImages) {
					if (image?.file) formData.append("image", image?.file)
					else if (image?.discarded) formData.append("discarded_images", image?.path)
				}

			if (coverImages?.discarded?.length)
				for (const path of coverImages?.discarded) formData.append("discarded_images", path)

			if (geolocation?.googlemap_placeid)
				for (const key in geolocation) formData.append(`geolocation[${key}]`, geolocation[key])

			if (data?.delivery_charges?.slabs)
				for (const index in data?.delivery_charges?.slabs) {
					formData.append(`delivery_charges[slabs][${index}][upto]`, data?.delivery_charges?.slabs?.[index]?.upto)
					formData.append(
						`delivery_charges[slabs][${index}][percent]`,
						data?.delivery_charges?.slabs?.[index]?.percent
					)
				}

			if (data?.business_nature) formData.append("business_nature", data?.business_nature)

			if (data?.product_types?.length)
				for (const i of data?.product_types) {
					formData.append("product_types", i)
				}

			if (coverImages?.primary && !coverImages?.primary?.uploaded) {
				formData.append("cover_images[primary]", coverImages?.primary?.name)
				formData.append("cover_image", coverImages?.primary?.file)
			}
			if (coverImages?.center?.top && !coverImages?.center?.top?.uploaded) {
				formData.append("cover_images[center][top]", coverImages?.center?.top?.name)
				formData.append("cover_image", coverImages?.center?.top?.file)
			}
			if (coverImages?.center?.bottom && !coverImages?.center?.bottom?.uploaded) {
				formData.append("cover_images[center][bottom]", coverImages?.center?.bottom?.name)
				formData.append("cover_image", coverImages?.center?.bottom?.file)
			}
			if (coverImages?.portrait && !coverImages?.portrait?.uploaded) {
				formData.append("cover_images[portrait]", coverImages?.portrait?.name)
				formData.append("cover_image", coverImages?.portrait?.file)
			}

			if (data?.delivery_timeframe) formData.set("delivery_timeframe", data?.delivery_timeframe)

			const response = await axios({
				method: business_id ? "put" : "post",
				url: business_id ? `business/${business_id}` : "/business",
				data: formData,
				headers: {
					"Content-Type": "multipart/form-data",
					"Authorization": `Bearer ${masterState.token}`
				}
			})

			if (response.data) {
				if (!masterState?.businesses?.find(i => i.id === response.data.id))
					setMasterState(prev => ({
						...prev,
						businesses: (prev?.businesses || [])?.concat([
							{
								id: response.data.id,
								title: response.data.title
							}
						])
					}))

				Navigate(`/business/${response.data.id}`)
				return
			}
		} catch (error) {
			console.log({ error })
		}
		setLoading(false)
	}

	const setCoverImage = async (data, type) => {
		const _type = type || coverImages?.selected_type

		if (data === undefined) data = await selectImage()
		if (data?.file) {
			const compressedImage = await compressImage(data.file, imageSizes.covers[_type])
			data.file = compressedImage
		}

		let discarded

		const selected = coverImages?.[_type] || coverImages?.center?.[_type]
		if (selected?.uploaded) discarded = selected?.path

		setCoverImages(prev => ({
			...(prev || {}),
			...(["primary", "portrait"]?.includes(_type)
				? { [_type]: data }
				: { center: { ...(prev?.center || {}), [_type]: data } }),
			selected: null,
			selected_type: null,
			discarded: [...(prev?.discarded || []), discarded]
		}))
	}

	const handleCoverImage = async type => {
		if (loading || businessDisabled) return
		try {
			const selected = coverImages?.[type]?.name || coverImages?.center?.[type]?.name
			if (selected)
				return setCoverImages(prev => ({
					...prev,
					selected: prev?.selected === selected ? null : selected,
					selected_type: type
				}))

			const image = await selectImage()
			setCoverImage(image, type)
		} catch (error) {
			setPromptState({
				heading: "Image Selection Error",
				message: error?.message || error,
				Actions: () => (
					<button className="theme-btn danger outlined" onClick={() => setPromptState()}>
						Okay
					</button>
				)
			})
		}
	}

	const deleteBusiness = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			await axios({
				method: "delete",
				url: `/business/${business_id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setMasterState(prev => ({
				...prev,
				businesses: prev?.businesses?.filter(i => i.id !== business_id)
			}))
			Navigate("/")
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const disableBusiness = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/business/${business_id}/disable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setData(response.data)
			setPromptState()
			setSelectedImages([])
			setCoverImages()
			setMasterState(prev => ({
				...prev,
				businesses: prev?.businesses?.map(i => (i.id === business_id ? { ...i, disabled: true } : i))
			}))
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const enableBusiness = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/business/${business_id}/enable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setData(response.data)
			setPromptState()
			setMasterState(prev => ({
				...prev,
				businesses: prev?.businesses?.map(i => (i.id === business_id ? { ...i, disabled: false } : i))
			}))
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const checkBusinessOrder = async () => {
		setLoading(true)
		try {
			const count = (
				await axios.get(`/business/${business_id}/order-count`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				})
			)?.data?.orderCount

			if (count)
				setPromptState({
					heading: "Disable Business",
					message: `Your business "${data?.title}" has ${count} order(s) in total and cannot be deleted. Would you like to disable it instead? After disabling, all product images and business images will be deleted and the business will not be visible to users. You can enable your business anytime.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={disableBusiness}>
								Disable Business
							</button>
						</>
					)
				})
			else
				setPromptState({
					heading: "Delete Business",
					message: `The selected business "${data?.title}", it's products & images will be deleted permanently.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={deleteBusiness}>
								Delete Business
							</button>
						</>
					)
				})
		} catch (error) {}
		setLoading(false)
	}

	const enableConfirmation = () => {
		setPromptState({
			heading: "Enable Business",
			message: `Activate this business now? Once enabled, it will be instantly accessible to users.`,
			Actions: () => (
				<>
					<button className="theme-btn danger outlined" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className="theme-btn primary" onClick={enableBusiness}>
						Enable Business
					</button>
				</>
			)
		})
	}

	const calculatorDeliveryCharges = () => {
		if (!deliveryChargeCalculator?.order_amount || !deliveryChargeCalculator?.delivery_distance) return `₹0`

		const { delivery_distance: distance, order_amount: amount } = deliveryChargeCalculator

		const slab =
			data?.delivery_charges?.slabs?.find(i => i.upto >= amount) || data?.delivery_charges?.slabs?.at(-1)

		const basePercentage = +slab?.percent || 0,
			extraPercentage = +data?.delivery_charges?.extra_percentage

		const baseCharges = +(amount * (basePercentage / 100)).toFixed(2),
			extraCharges = extraPercentage ? (distance / 100) * (amount * (extraPercentage / 100)) : 0

		const totalCharges = toAmount(baseCharges + extraCharges)

		return (
			<table>
				<tbody>
					{basePercentage > 0 && (
						<tr>
							<td className="text-right">
								{basePercentage}% of {amount}
							</td>
							<td> = ₹{toAmount(baseCharges)}</td>
							<td>
								<span className="font-s">
									(From slab{" "}
									{slab.upto === data?.delivery_charges?.slabs?.at(-1)?.upto
										? `above ${toAmount(slab.from)}`
										: `${toAmount(slab.from)} - ${toAmount(slab.upto)}`}
									)
								</span>
							</td>
						</tr>
					)}
					{extraCharges > 0 && (
						<tr>
							<td className="text-right">
								{baseCharges ? "+" : ""} {+(extraPercentage * (distance / 100)).toFixed(2)}% of {amount}
							</td>
							<td> = ₹{toAmount(extraCharges)}</td>
							<td>
								<span className="font-s">
									({extraPercentage}% per 100KM of {distance}KM = ({extraPercentage}*{distance / 100})%)
								</span>
							</td>
						</tr>
					)}
					<tr>
						<td className="text-right">
							<b>Total</b>
						</td>
						<td>
							{" "}
							= <b>₹{totalCharges}</b>
						</td>
						<td></td>
					</tr>
				</tbody>
			</table>
		)
	}

	let refCount = 0
	const handleServiceValidation = () => {
		const checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"].services'))
		const isOneChecked = checkboxes?.find(i => i.checked)
		checkboxes?.map(e => isOneChecked && e.setCustomValidity(""))
	}

	return (
		<div id="crbusiness" className="page-wrapper">
			<Navbar />
			<div id="container">
				<div id="toc">
					<h2 className="font-m">Create your business page</h2>
					<hr />
					{toc.map((i, idx) => (
						<div
							key={i.heading + idx}
							className={idx === activeSectionIndex ? "selected" : ""}
							onClick={() => scrollToSection(i)}
						>
							<div className="font-md index">
								<span>{idx + 1}</span>
							</div>
							<div>
								<div>
									<span className="font-m">{i.heading}</span>
								</div>
							</div>
						</div>
					))}
				</div>
				<div id="content">
					<div className="flex between">
						<div className="heading flex col start no-gap">
							<h1 className="heading" style={{ margin: 0 }}>
								Business Information
							</h1>
							{businessDisabled && (
								<small className="font-xs">To modify any details, activate the business.</small>
							)}
						</div>
						{business_id &&
							(businessDisabled ? (
								data?.active_status?.by_admin ? (
									<button type="button" className="theme-btn primary flex disabled" onClick={enableConfirmation}>
										<GoCheckCircle className="font-l" /> Enable Business
									</button>
								) : null
							) : (
								<button type="button" className="theme-btn outlined danger flex" onClick={checkBusinessOrder}>
									<Delete /> Delete This Business
								</button>
							))}
					</div>
					<form onSubmit={onSubmit} className={businessDisabled ? "disabled" : ""}>
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div>
								<h2 className="font-l">Business details</h2>
								<div className="font-md">
									<span>Business name, nature and behaviour</span>
								</div>
							</div>
							<div>
								<label htmlFor="title">
									<span className="font-s">Business Title</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									id="title"
									className="theme-input"
									placeholder="Name of your business"
									name="title"
									defaultValue={""}
									value={data?.title}
									onChange={onChange}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
							<div>
								<label htmlFor="product-category">
									<span className="font-s">Primary Nature Of Business</span>
									<span className="danger-font">*</span>
								</label>
								<Select
									DropDownIcon={() => <Down opacity={".7"} />}
									options={businessNatures}
									placeholder="Select your business nature"
									defaultSelection={data.business_nature}
									onSelect={i => setData(prev => ({ ...prev, business_nature: i }))}
									disabled={loading || businessDisabled}
								/>
							</div>
							{!isNatureService && (
								<>
									<div>
										<label htmlFor="product-category">
											<span className="font-s">What Do You Sell?</span>
											<span className="danger-font">*</span>
										</label>
										<Select
											DropDownIcon={() => <Down opacity={".7"} />}
											options={productTypes}
											placeholder="Select product type"
											defaultSelection={data.product_types}
											onSelect={i => setData(prev => ({ ...prev, product_types: i }))}
											disabled={loading || businessDisabled}
											multiselect={true}
										/>
										<div>
											<small className="font-xs">
												Tools and accessories include gardening tools and accessories
											</small>
										</div>
									</div>
									<div>
										<label htmlFor="min_order_value">
											<span className="font-s">Minimum Order Value (₹)</span>
											<span className="danger-font">*</span>
										</label>
										<input
											type="number"
											id="min_order_value"
											className="theme-input"
											placeholder="e.g. 399"
											name="min_order_value"
											defaultValue={""}
											value={data?.min_order_value}
											onChange={onChange}
											disabled={loading || businessDisabled}
											required
										/>
									</div>
									<div>
										<label>
											<span className="font-s">Delivery timeframe</span>
											<span className="danger-font">*</span>
										</label>
										<div className="theme-input">
											<div className="flex row start">
												{deliveryTimeframe?.map(i => (
													<label key={i.value} className="flex start">
														<input
															type="radio"
															name="delivery_timeframe"
															checked={data?.delivery_timeframe === i.value}
															onChange={() => setData(prev => ({ ...prev, delivery_timeframe: i.value }))}
															hidden
														/>
														<Radio className={`radio ${data?.delivery_timeframe === i.value ? "active" : ""}`} />
														<span>{i.label}</span>
													</label>
												))}
											</div>
										</div>
									</div>
									<div>
										<label className="flex start">
											<input
												type="checkbox"
												name="deliver_all_over_india"
												checked={data?.deliver_all_over_india}
												onChange={e => setData(prev => ({ ...prev, deliver_all_over_india: e.target.checked }))}
												hidden
											/>
											{data?.deliver_all_over_india ? <CheckBox /> : <CheckBoxEmpty />}
											<span>Do you deliver orders all over India? (Deliver within 4 to 15 days)</span>
										</label>
									</div>
									<div>
										<label htmlFor="delivery_range">
											<span className="font-s">Delivery Range (Kilometers)</span>
										</label>
										<input
											type="text"
											className="theme-input"
											placeholder="e.g. 20 KM"
											id="delivery_range"
											name="delivery_range"
											defaultValue={""}
											value={data?.delivery_range}
											onChange={onChange}
											disabled={loading || businessDisabled}
										/>
									</div>
								</>
							)}
							<div>
								<div>
									<span className="font-s">Select Your Services</span>
									{isNatureService ? (
										<span className="danger-font">*</span>
									) : (
										<span className="font-s"> (if applicable)</span>
									)}
								</div>
								<div className="services-wrapper">
									{services.map(({ key, label }) => (
										<label key={key}>
											<div className="relative flex">
												<input
													type="checkbox"
													id={`service:${key}`}
													className="hidden-input-checkbox services"
													onInvalid={e =>
														e.target.setCustomValidity(
															isNatureService ? "Select atlease one service for your service based businesss." : ""
														)
													}
													name={`services[${key}]`}
													value={Boolean(data?.services?.[key])}
													checked={Boolean(data?.services?.[key])}
													onChange={e => {
														e.target.value = e.target.checked
														handleServiceValidation()
														setData(prev => ({
															...prev,
															services: { ...prev?.services, [key]: e.target.checked }
														}))
													}}
													required={isNatureService && !Object.values(data?.services || {})?.some(i => i === true)}
												/>
												{data?.services?.[key] ? <CheckBox /> : <CheckBoxEmpty />}
											</div>
											<span className="font-md">{label}</span>
										</label>
									))}
								</div>
							</div>
						</div>
						{!isNatureService && (
							<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
								<h2 className="font-l">Business Delivery Charges</h2>
								<div>
									<label htmlFor="delivery_charge_extra">
										<span className="font-s">Per 100Km % of order price</span>
									</label>
									<input
										type="number"
										className="theme-input"
										placeholder="e.g. 1% of order amount"
										id="delivery_charge_extra"
										name="delivery_charges[extra_percentage]"
										defaultValue={""}
										value={data?.delivery_charges?.extra_percentage}
										onChange={event =>
											+event.target.value >= 0 && +event.target.value <= 99 && onChangeNested(event)
										}
										disabled={loading || businessDisabled}
									/>
								</div>
								<div>
									<table className="w-full" id="delivery-charge-ranges">
										<thead>
											<tr>
												<th className="text-left">Order price range</th>
												<th className="text-left">Delivery charge (%)</th>
											</tr>
										</thead>
										<tbody>
											{orderPriceSlabs?.map((i, idx) => (
												<tr>
													<td
														style={{
															opacity: data?.delivery_charges?.slabs?.find(_i => _i.upto === i.upto) ? 1 : 0.5
														}}
													>
														{i.upto === data?.delivery_charges?.slabs?.at(-1)?.upto
															? `above ₹${toAmount(i.from)}`
															: `₹${toAmount(i.from)} - ${toAmount(i.upto)}`}
													</td>
													<td>
														<input
															type="number"
															className="theme-input"
															placeholder="e.g. 10%"
															value={data?.delivery_charges?.slabs?.find(_i => _i.upto === i.upto)?.percent}
															onFocus={e => e.target.select()}
															onChange={event =>
																event.target.value >= 0 &&
																event.target.value <= 99 &&
																handleDeliveryCharge(i.upto, event.target.value, idx)
															}
														/>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div id="delivery-charge-calculator">
									<div htmlFor="product-category">
										<b>
											<span className="font-md visible">Delivery Charges Calculator</span>
										</b>
										<br />
										<span className="font-s">Test the above delivery charge configuration</span>
									</div>
									<div className="flex row start">
										<label htmlFor="test_order_amount">
											<span className="label">Order amount</span>
										</label>
										<input
											type="number"
											className="theme-input bottom-border"
											style={{ width: "9rem" }}
											placeholder="e.g. ₹5000"
											id="test_order_amount"
											defaultValue={""}
											value={deliveryChargeCalculator?.order_amount}
											onChange={e =>
												setDeliveryChargeCalculator(prev => ({ ...prev, order_amount: e.target.value }))
											}
											disabled={loading || businessDisabled}
										/>
									</div>
									<div className="flex row start">
										<label htmlFor="test_delivery_distance">
											<span className="label">Delivery distance</span>
										</label>
										<input
											type="number"
											className="theme-input bottom-border"
											style={{ width: "9rem" }}
											placeholder="e.g. 12KM"
											id="test_delivery_distance"
											defaultValue={""}
											value={deliveryChargeCalculator?.delivery_distance}
											onChange={e =>
												setDeliveryChargeCalculator(prev => ({ ...prev, delivery_distance: e.target.value }))
											}
											disabled={loading || businessDisabled}
										/>
									</div>
									<div className="flex row start" style={{ alignItems: "flex-start" }}>
										<span className="label">Delivery charges</span>
										<div>{calculatorDeliveryCharges()}</div>
									</div>
								</div>
							</div>
						)}
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div>
								<h2 className="font-l">Location details</h2>
								<div className="font-md">
									<span>Address and geolocation</span>
								</div>
							</div>
							<div>
								<label htmlFor="product-category">
									<span className="font-s">Your City</span>
									<span className="danger-font">*</span>
								</label>
								<Select
									DropDownIcon={() => <Down opacity={".7"} />}
									options={masterState?.cities?.map(i => ({ label: i?.name, value: i?._id }))}
									placeholder="Select your city"
									defaultSelection={data.city}
									onSelect={i => setData(prev => ({ ...prev, city: i }))}
									disabled={loading || businessDisabled}
								/>
							</div>
							<div>
								<div>
									<label htmlFor="complete_address">
										<span className="font-s">Complete Address</span>
										<span className="danger-font">*</span>
									</label>
									<input
										type="text"
										className="theme-input"
										placeholder="Business Complete Address"
										id="complete_address"
										name="complete_address"
										defaultValue={""}
										value={data?.complete_address}
										onChange={onChange}
										disabled={loading || businessDisabled}
										required
									/>
								</div>
								<div>
									<small className="font-xs">
										Please ensure this is same as the address on your License /Govt. document (if applicable)
									</small>
								</div>
							</div>
							<div>
								<label htmlFor="locality">
									<span className="font-s">Locality</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Enter your business locality, eg. Sector 43, Gurgaon"
									id="locality"
									name="locality"
									defaultValue={""}
									value={data?.locality}
									onChange={onChange}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
							<label htmlFor="locality">
								<span className="font-s">Geolocation</span>
								<span className="danger-font">*</span>
							</label>
							<Places selected={geolocation} setSelected={setGeolocation} />
						</div>
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div>
								<h2 className="font-l">Legal & Bank details</h2>
								<div className="font-md">
									<span>Business's legal and bank details</span>
								</div>
							</div>
							<div>
								<label htmlFor="pan">
									<span className="font-s">Business PAN</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Permanent account number"
									name="pan"
									defaultValue={""}
									value={data?.pan}
									onChange={onChange}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
							<div>
								<label htmlFor="gstin" className="font-s">
									Business GSTIN
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Enter your 15 digit GST identification number"
									id="gstin"
									name="gstin"
									defaultValue={""}
									value={data?.gstin}
									onChange={onChange}
									disabled={loading || businessDisabled}
								/>
							</div>
							<div>
								<label htmlFor="bank_account_number">
									<span className="font-s">Bank Account Number</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Your account number"
									id="bank_account_number"
									name="bank_details[account_number]"
									defaultValue={""}
									value={data?.bank_details?.account_number}
									onChange={onChangeNested}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
							<div>
								<label htmlFor="bank_account_name">
									<span className="font-s">Account Holder's Name</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Name of the account holder"
									id="bank_account_name"
									name="bank_details[account_name]"
									defaultValue={""}
									value={data?.bank_details?.account_name}
									onChange={onChangeNested}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
							<div>
								<label htmlFor="ifsc">
									<span className="font-s">Bank IFSC</span>
									<span className="danger-font">*</span>
								</label>
								<input
									type="text"
									className="theme-input"
									placeholder="Enter bank's IFSC code"
									id="ifsc"
									name="bank_details[ifsc]"
									defaultValue={""}
									value={data?.bank_details?.ifsc}
									onChange={onChange}
									disabled={loading || businessDisabled}
									required
								/>
							</div>
						</div>
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div>
								<h2 className="font-l">Contact Number At Business</h2>
								<div className="font-md">
									<span>Your customers will call on this number for general enquiries</span>
								</div>
							</div>
							<NumberInput
								name="contact_number[primary]"
								defaultValue={""}
								verify={
									data?.contact_number?.primary?.length === 10 && !verifiedNumbers[data?.contact_number?.primary]
								}
								value={data?.contact_number?.primary}
								onChange={onChangeNested}
								disabled={loading || businessDisabled}
								onVerified={() => setVerifiedNumbers(prev => ({ ...prev, [data?.contact_number?.primary]: true }))}
								required
							/>
							<div className="dashed-heading">
								<hr />
								<span className="font-md">share a secondary number</span>
								<hr />
							</div>
							<NumberInput
								placeholder={"Enter your secondary number"}
								name="contact_number[secondary]"
								verify={
									data?.contact_number?.secondary?.length === 10 &&
									!verifiedNumbers[data?.contact_number?.secondary]
								}
								defaultValue={""}
								value={data?.contact_number?.secondary}
								onChange={onChangeNested}
								disabled={loading || businessDisabled}
								onVerified={() =>
									setVerifiedNumbers(prev => ({ ...prev, [data?.contact_number?.secondary]: true }))
								}
							/>
						</div>
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div className="flex between">
								<div>
									<h2 className="font-l">Discount Table</h2>
									<div className="font-md">
										<span>Manage discount on billing amount of your business orders</span>
									</div>
								</div>
								<button
									type="button"
									className="theme-btn small primary flex"
									onClick={e => setData(prev => ({ ...prev, discounts: (prev.discounts || []).concat([{}]) }))}
								>
									<Add stroke="white" width={"20px"} height={"20px"} /> Add row
								</button>
							</div>
							<table id="discount-table">
								<thead>
									<tr>
										<th>Minimum Billing Amount</th>
										<th>Discount(%)</th>
									</tr>
								</thead>
								<tbody>
									{data?.discounts?.map((i, idx) => (
										<tr key={"discount-row-" + idx}>
											<td>
												<input
													type="number"
													className="theme-input"
													placeholder="Rs.0.00"
													name={`discounts[${idx}][min_amount]`}
													value={i.min_amount}
													onChange={onChangeIndexed}
												/>
											</td>
											<td>
												<input
													type="number"
													className="theme-input"
													placeholder="5%"
													name={`discounts[${idx}][discount]`}
													value={i.discount}
													max={99}
													onChange={onChangeIndexed}
												/>
											</td>
										</tr>
									)) || (
										<tr>
											<td>
												<input
													type="text"
													className="theme-input"
													placeholder="Rs.0.00"
													name={`discounts[0][min_amount]`}
													onChange={onChangeIndexed}
												/>
											</td>
											<td>
												<input
													type="text"
													className="theme-input"
													placeholder="5%"
													name={`discounts[0][discount]`}
													onChange={onChangeIndexed}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<ImageUpload
							images={selectedImages}
							wrapperClasses={"shadow"}
							imagesPerRow={3}
							imageSize={imageSizes.business}
							setImages={setSelectedImages}
							heading={"Upload Business Images"}
							disabled={loading || businessDisabled}
							id={toc?.[refCount]?.id}
							__ref={sectionRefs.current[refCount++]}
						/>
						<div className="section shadow" id={toc?.[refCount]?.id} ref={sectionRefs.current[refCount++]}>
							<div className="flex between">
								<div>
									<h2 className="font-l">Select Business Cover Images</h2>
								</div>
								{coverImages?.selected && (
									<div className="flex">
										<button
											type="button"
											className={`theme-btn small danger flex`}
											onClick={() => setCoverImage(null)}
											disabled={loading || businessDisabled}
										>
											<RxCross2 width={"15px"} height={"15px"} /> Remove
										</button>
										<button
											type="button"
											className="theme-btn small primary flex"
											onClick={() => setCoverImage()}
											disabled={loading || businessDisabled}
										>
											<GrPowerReset stroke="white" width={"20px"} height={"20px"} /> Change
										</button>
									</div>
								)}
							</div>
							<div>
								<div id="business_cover_images" className="w-full">
									<div style={{ "--rounded": "5px" }}>
										<div id="primary-cover" className="relative" onClick={() => handleCoverImage("primary")}>
											<img src={coverImages?.primary?.url || "/images/placeholder.webp"} alt="Cover" />
											{coverImages?.selected && coverImages?.selected === coverImages?.primary?.name && (
												<div className="flex selection">
													<Check />
												</div>
											)}
										</div>
										<div id="center-cover">
											<div className="relative" onClick={() => handleCoverImage("top")}>
												<img src={coverImages?.center?.top?.url || "/images/placeholder.webp"} alt="Cover" />
												{coverImages?.selected && coverImages?.selected === coverImages?.center?.top?.name && (
													<div className="flex selection">
														<Check />
													</div>
												)}
											</div>
											<div className="relative" onClick={() => handleCoverImage("bottom")}>
												<img src={coverImages?.center?.bottom?.url || "/images/placeholder.webp"} alt="Cover" />
												{coverImages?.selected && coverImages?.selected === coverImages?.center?.bottom?.name && (
													<div className="flex selection">
														<Check />
													</div>
												)}
											</div>
										</div>
										<div id="portrait-cover" className="relative" onClick={() => handleCoverImage("portrait")}>
											<img src={coverImages?.portrait?.url || "/images/placeholder.webp"} alt="Cover" />
											{coverImages?.selected && coverImages?.selected === coverImages?.portrait?.name && (
												<div className="flex selection">
													<Check />
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="font-md">
								<span>
									First image is necessary to make the covers visible. 2nd and 3rd images must be filled as a pair.
								</span>
							</div>
						</div>
						<div style={{ margin: "10px 0" }}>
							<label style={{ display: "flex", alignItems: "center", gap: "10px", position: "relative" }}>
								<input
									type="checkbox"
									style={{ position: "absolute", top: "25%", left: 5, zIndex: -1, pointerEvents: "none" }}
									value={Boolean(data?.termsAccepted)}
									onChange={e => setData(prev => ({ ...prev, termsAccepted: e.target.checked }))}
									required
								/>
								{data?.termsAccepted ? <CheckBox /> : <CheckBoxEmpty />}
								<div className="font-md">
									<span>I have read and I agree with the </span>
									<a
										style={{ display: "inline" }}
										target="_blank"
										href={"/terms-of-sale"}
										className="underline theme-font"
										rel="noreferrer"
									>
										Terms Of Sale
									</a>
									.
								</div>
							</label>
						</div>
						<div>
							<button
								type="submit"
								className="theme-btn primary flex form-submit relative"
								disabled={loading || businessDisabled}
							>
								{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
								{business_id ? "Update Business" : "Create Business"}
							</button>
						</div>
					</form>
				</div>
			</div>
			{loading === "spinner" && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
			<Footer />
		</div>
	)
}

export default BusinessForm
