import React, { useContext, useEffect, useState } from "react"
import { ReactComponent as Down } from "../../assets/down.svg"
import { ReactComponent as Delete } from "../../assets/delete.svg"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import Select from "../../components/Select"
import Prompt from "../../components/Prompt"
import ImageUpload from "../../components/ImageUpload"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import { GoCheckCircle } from "react-icons/go"
import { IoArrowBack } from "react-icons/io5"
import { MasterContext } from "../../context/MasterContext"
import "./styles.css"
import { imageSizes } from "../../utils/compressImage"

const containers = [
	// "Planter Box",
	// "Grow Bag",
	// "Business Tray",
	// "Terrarium",
	// "Burlap Sack",
	// "Mesh Bag",
	// "Potted Sleeve",
	// "Biodegradable Pot"
	"Pot",
	"Polybag",
	"Jutebag",
	"Hanging basket"
]

const ProductForm = () => {
	const navigate = useNavigate()
	const { business_id, product_id } = useParams()
	const { masterState } = useContext(MasterContext)
	const [selectedImages, setSelectedImages] = useState([])
	const [productData, setProductData] = useState({})
	const [promptState, setPromptState] = useState()
	const [categories, setCategories] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		if (masterState?.categories) setCategories(masterState?.categories?.filter(i => !i.disabled))
	}, [masterState])

	useEffect(() => {
		if (!product_id) return
		;(async () => {
			setLoading("spinner")
			try {
				let response = await axios.get("/product/" + product_id)
				if (response.data) {
					setProductData(response.data)
					if (response?.data?.images?.[0])
						setSelectedImages(
							response.data.images.map(i => ({
								...i,
								path: i.path,
								url: `${axios.defaults.baseURL}/${i.path}`,
								uploaded: true
							}))
						)
				}
			} catch (error) {}
			setLoading()
		})()
	}, [product_id])

	useEffect(() => {
		if (!productData?.primary_image && selectedImages?.length === 1) {
			setProductData(prev => ({
				...prev,
				primary_image: selectedImages?.[0]?.name
			}))
		}
	}, [selectedImages, productData])

	const product_price =
		(+productData.retail_price - ((+productData.retail_price / 100) * +productData.discount || 0)).toFixed(2) || ""

	const setData = e => setProductData(prev => ({ ...prev, [e.target.name]: e.target.value }))
	const onSubmit = async e => {
		setLoading(true)
		try {
			e.preventDefault()
			const formData = new FormData(e.target)
			formData.append("category", productData?.category)
			formData.append("price", product_price)
			formData.append("business_id", business_id)

			if (product_id) formData.append("product_id", product_id)
			if (productData?.subcategory) formData.append("subcategory", productData?.subcategory)
			if (productData?.primary_image) formData.append("primary_image", productData?.primary_image)
			if (productData?.plant_is_in) formData.append("plant_is_in", productData?.plant_is_in)

			for (const image of selectedImages) {
				if (image?.file) formData.append("product_image", image?.file)
				else if (image?.discarded) formData.append("discarded_images", image?.path)
			}

			await axios({
				method: product_id ? "put" : "post",
				url: "/product",
				headers: {
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
					"Content-Type": "multipart/form-data"
				},
				data: formData
			})

			setLoading(false)
			navigate(`/business/${business_id}`)
		} catch (error) {
			console.error(error)
			setLoading(false)
		}
	}

	const deleteProduct = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			await axios({
				method: "delete",
				url: `/product/${product_id}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			navigate("/")
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const disableProduct = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/product/${product_id}/disable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setProductData(response.data)
			setPromptState()
			setSelectedImages([])
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const enableProduct = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/product/${product_id}/enable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setProductData(response.data)
			setPromptState()
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const checkBusinessOrder = async () => {
		setLoading(true)
		try {
			const count = (
				await axios.get(`/product/${product_id}/order-count`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				})
			)?.data?.orderCount

			if (count)
				setPromptState({
					heading: "Disable Product",
					message: `Your product "${productData?.name}" was used in ${count} order(s) in total and cannot be deleted. Would you like to disable it instead? After disabling, the images will be deleted and the product will not be visible to users.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={disableProduct}>
								Disable Product
							</button>
						</>
					)
				})
			else
				setPromptState({
					heading: "Delete Product",
					message: `The selected product "${productData?.name}" & images will be deleted permanently.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={deleteProduct}>
								Delete Product
							</button>
						</>
					)
				})
		} catch (error) {}
		setLoading(false)
	}

	const enableConfirmation = () => {
		setPromptState({
			heading: "Enable Product",
			message: `Activate this product now? Once enabled, it will be instantly accessible to users.`,
			Actions: () => (
				<>
					<button className="theme-btn danger outlined" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className="theme-btn primary" onClick={enableProduct}>
						Enable Product
					</button>
				</>
			)
		})
	}

	return (
		<div id="product-form" className="page-wrapper">
			<Navbar />
			<form className={`container ${productData?.disabled ? "disabled" : ""}`} onSubmit={onSubmit}>
				<div>
					<div className="flex col start no-gap">
						<div className="flex col start no-gap">
							<h1 className="heading">{product_id ? "Update" : "Add"} product</h1>
							{productData?.disabled && (
								<small className="font-xs">To modify any details, activate the product.</small>
							)}
						</div>
						<button className="flex start gap-xs" onClick={() => navigate(`/business/${business_id}`)}>
							<IoArrowBack /> Back
						</button>
					</div>
					{product_id &&
						(productData?.disabled ? (
							<button type="button" className="theme-btn primary flex" onClick={enableConfirmation}>
								<GoCheckCircle className="font-l" /> Enable Product
							</button>
						) : (
							<button type="button" className="theme-btn outlined danger flex" onClick={checkBusinessOrder}>
								<Delete /> Delete Product
							</button>
						))}
				</div>
				<div className="section">
					<div>
						<h2 className="font-l">Product details</h2>
						<div className="font-s">
							<span>Name, category, price and about</span>
						</div>
					</div>
					<div className="p-fields full">
						<div>
							<label htmlFor="product-name" className="font-s">
								Product Name
							</label>
							<input
								type="text"
								id="product-name"
								className="theme-input"
								placeholder="e.g. Begonia"
								name="name"
								defaultValue={""}
								value={productData?.name}
								onChange={setData}
								disabled={loading || productData?.disabled}
								required={true}
							/>
						</div>
					</div>
					<div className="p-fields">
						<div>
							<label htmlFor="stock" className="font-s">
								Stock
							</label>
							<input
								type="number"
								id="stock"
								className="theme-input"
								name="stock"
								defaultValue={""}
								value={productData?.stock}
								onChange={setData}
								disabled={loading || productData?.disabled}
								required={true}
							/>
						</div>
						<div>
							<label htmlFor="min_order_qty" className="font-s">
								Minimum Order Quantity
							</label>
							<input
								type="number"
								id="min_order_qty"
								className="theme-input"
								name="min_order_qty"
								defaultValue={""}
								value={productData?.min_order_qty}
								onChange={setData}
								disabled={loading || productData?.disabled}
							/>
						</div>
					</div>
					<div className="p-fields">
						<div>
							<label htmlFor="product-category" className="font-s">
								Category
							</label>
							<Select
								DropDownIcon={() => <Down opacity={".7"} />}
								options={categories?.map(i => ({ label: i.name, value: i._id }))}
								placeholder="None"
								defaultSelection={productData.category}
								onSelect={i => setProductData(prev => ({ ...prev, category: i, subcategory: null }))}
								disabled={loading || productData?.disabled}
								required={true}
							/>
						</div>
						{!productData.category ||
						categories?.find(i => i._id === productData.category)?.subcategories?.length ? (
							<div>
								<label htmlFor="product-subcategory" className="font-s">
									Sub Category
								</label>
								<Select
									DropDownIcon={() => <Down opacity={".7"} />}
									options={categories
										?.find(i => i._id === productData.category)
										?.subcategories?.map(i => ({
											label: i.name,
											value: i._id
										}))}
									placeholder={!productData.category ? "--Select a category first--" : "None"}
									defaultSelection={productData.subcategory}
									onSelect={i => setProductData(prev => ({ ...prev, subcategory: i }))}
									disabled={!productData.category || loading || productData?.disabled}
									required={true}
								/>
							</div>
						) : categories?.find(i => i._id === productData?.category)?.name?.toLowerCase() === "tools" ? (
							<div>
								<label htmlFor="color" className="font-s">
									Color
								</label>
								<input
									type="text"
									id="color"
									className="theme-input"
									placeholder="e.g. Brown"
									name="color"
									defaultValue={""}
									value={productData?.color}
									onChange={setData}
									disabled={loading || productData?.disabled}
								/>
							</div>
						) : (
							""
						)}
					</div>
					{categories?.find(i => i._id === productData?.category)?.name?.toLowerCase() === "plants" ? (
						<>
							<div className="p-fields">
								<div>
									<label htmlFor="product-is-in" className="font-s">
										Plant is in
									</label>
									<Select
										DropDownIcon={() => <Down opacity={".7"} />}
										options={containers?.map(i => ({ value: i, label: i }))}
										placeholder={"None"}
										defaultSelection={productData.plant_is_in}
										onSelect={i => setProductData(prev => ({ ...prev, plant_is_in: i }))}
										disabled={loading || productData?.disabled}
										required={true}
									/>
								</div>
								<div>
									<label htmlFor="product-price" className="font-s">
										Size • Height (inches)
									</label>
									<input
										type="number"
										id="product-price"
										className="theme-input"
										placeholder="e.g. 32 inches"
										name="size[height]"
										defaultValue={""}
										value={productData?.size?.height}
										onChange={e =>
											setProductData(prev => ({ ...prev, size: { ...(prev.size || {}), height: e.target.value } }))
										}
										disabled={loading || productData?.disabled}
										required={true}
									/>
								</div>
								<div>
									<label htmlFor="product-price" className="font-s">
										Width/Spread (inches)
									</label>
									<input
										type="number"
										id="product-price"
										className="theme-input"
										placeholder="e.g. 18 inches"
										name="size[width_spread]"
										defaultValue={""}
										value={productData?.size?.width_spread}
										onChange={e =>
											setProductData(prev => ({
												...prev,
												size: { ...(prev.size || {}), width_spread: e.target.value }
											}))
										}
										disabled={loading || productData?.disabled}
										required={true}
									/>
								</div>
							</div>
							<div className="p-fields">
								<div>
									<label htmlFor="variety" className="font-s">
										Variety
									</label>
									<input
										type="text"
										id="variety"
										className="theme-input"
										name="variety"
										defaultValue={""}
										value={productData?.variety}
										onChange={setData}
										disabled={loading || productData?.disabled}
									/>
								</div>
								<div>
									<label htmlFor="color" className="font-s">
										Color
									</label>
									<input
										type="text"
										id="color"
										className="theme-input"
										placeholder="e.g. Green"
										name="color"
										defaultValue={""}
										value={productData?.color}
										onChange={setData}
										disabled={loading || productData?.disabled}
									/>
								</div>
							</div>
						</>
					) : categories?.find(i => i._id === productData?.category)?.name?.toLowerCase() === "seeds" ? (
						<div className="p-fields">
							<div>
								<label htmlFor="avg-seed-count" className="font-s">
									Avg. Seeds Count
								</label>
								<input
									type="number"
									id="avg-seed-count"
									className="theme-input"
									name="avg_seed"
									defaultValue={""}
									value={productData?.avg_seed}
									onChange={setData}
									disabled={loading || productData?.disabled}
								/>
							</div>
							<div>
								<label htmlFor="variety" className="font-s">
									Variety
								</label>
								<input
									type="text"
									id="variety"
									className="theme-input"
									name="variety"
									defaultValue={""}
									value={productData?.variety}
									onChange={setData}
									disabled={loading || productData?.disabled}
								/>
							</div>
							<div>
								<label htmlFor="seed-kind" className="font-s">
									Kind
								</label>
								<input
									type="text"
									id="seed-kind"
									className="theme-input"
									name="kind"
									defaultValue={""}
									value={productData?.kind}
									onChange={setData}
									disabled={loading || productData?.disabled}
								/>
							</div>
						</div>
					) : categories?.find(i => i._id === productData?.category)?.name?.toLowerCase() === "fertilizers" ? (
						<>
							<div className="p-fields full">
								<div>
									<label htmlFor="product-weight" className="font-s">
										Weight (KG)
									</label>
									<input
										type="number"
										id="product-weight"
										className="theme-input"
										name="weight"
										defaultValue={""}
										value={productData?.weight}
										onChange={setData}
										disabled={loading || productData?.disabled}
										placeholder="e.g.10 KG"
										required={true}
									/>
								</div>
							</div>
						</>
					) : categories?.find(i => i._id === productData?.category)?.name?.toLowerCase() === "pots" ? (
						<>
							<div className="p-fields">
								<div>
									<label htmlFor="product-price" className="font-s">
										Size • Height
									</label>
									<input
										type="number"
										id="product-price"
										className="theme-input"
										placeholder='e.g. 32"'
										name="size[height]"
										defaultValue={""}
										value={productData?.size?.height}
										onChange={e =>
											setProductData(prev => ({ ...prev, size: { ...(prev.size || {}), height: e.target.value } }))
										}
										disabled={loading || productData?.disabled}
										required={true}
									/>
								</div>
								<div>
									<label htmlFor="product-price" className="font-s">
										Width/Spread
									</label>
									<input
										type="number"
										id="product-price"
										className="theme-input"
										placeholder='e.g. 18"'
										name="size[width_spread]"
										defaultValue={""}
										value={productData?.size?.width_spread}
										onChange={e =>
											setProductData(prev => ({
												...prev,
												size: { ...(prev.size || {}), width_spread: e.target.value }
											}))
										}
										disabled={loading || productData?.disabled}
										required={true}
									/>
								</div>
								<div>
									<label htmlFor="color" className="font-s">
										Color
									</label>
									<input
										type="text"
										id="color"
										className="theme-input"
										placeholder="e.g. Blue"
										name="color"
										defaultValue={""}
										value={productData?.color}
										onChange={setData}
										disabled={loading || productData?.disabled}
									/>
								</div>
							</div>
						</>
					) : (
						""
					)}
					<div className="p-fields">
						<div style={{ width: "calc(33.33% - 10px)" }}>
							<label htmlFor="product-wrpr" className="font-s">
								Retail Price
							</label>
							<input
								type="number"
								id="product-price"
								className="theme-input"
								placeholder="₹0.00"
								name="retail_price"
								defaultValue={""}
								value={productData?.retail_price}
								onChange={setData}
								disabled={loading || productData?.disabled}
								required={true}
							/>
						</div>
						<div style={{ width: "calc(33.33% - 10px)" }}>
							<label htmlFor="product-price" className="font-s">
								Discount(%)
							</label>
							<input
								type="number"
								id="product-price"
								className="theme-input"
								placeholder="0%"
								name="discount"
								defaultValue={""}
								value={productData?.discount}
								onChange={e => (+e.target.value > 100 ? "" : setData(e))}
							/>
						</div>
						<div style={{ width: "calc(33.33% - 10px)" }}>
							<label htmlFor="product-price" className="font-s">
								Price
							</label>
							<input
								type="number"
								id="product-price"
								className="theme-input"
								placeholder="₹0.00"
								name="price"
								defaultValue={""}
								value={product_price}
								disabled
							/>
						</div>
					</div>
					<div className="p-fields full">
						<div>
							<label htmlFor="product-name" className="font-s">
								Description
							</label>
							<textarea
								rows={5}
								id="product-price"
								className="theme-input"
								placeholder="About the product"
								name="description"
								defaultValue={""}
								value={productData?.description}
								onChange={setData}
								disabled={loading || productData?.disabled}
								required={true}
							/>
						</div>
					</div>
					{/* <div>
						<div>
							<small className="font-xs">
								Please ensure the provided product size is in inches
							</small>
						</div>
					</div> */}
				</div>
				<ImageUpload
					images={selectedImages}
					setImages={setSelectedImages}
					heading={"Upload Product Images"}
					maxCount={4}
					imageSize={imageSizes.product}
					highlightImages={[productData?.primary_image]}
					disabled={loading || productData?.disabled}
				/>
				<div>
					<div>
						<label htmlFor="product-subcategory" className="font-s">
							Select primary product image
						</label>
						<Select
							DropDownIcon={() => <Down opacity={".7"} />}
							options={selectedImages?.map(i => ({
								label: i.name,
								value: i.name
							}))}
							placeholder={!selectedImages?.length ? "--Select an image first--" : "None"}
							defaultSelection={productData?.primary_image}
							onSelect={i => setProductData(prev => ({ ...prev, primary_image: i }))}
							disabled={!selectedImages?.length || loading || productData?.disabled}
						/>
					</div>
				</div>
				<div>
					<button type="submit" className="theme-btn primary flex form-submit relative">
						{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
						{product_id ? "Update" : "Submit"}
					</button>
				</div>
			</form>
			{promptState && (
				<Prompt
					heading="Delete Product"
					message='The selected product "product-name" will be removed permanently.'
					Actions={() => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger">Delete</button>
						</>
					)}
				/>
			)}
			{loading === "spinner" && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
			<Footer />
		</div>
	)
}

export default ProductForm
